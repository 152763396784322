function scrollToTarget(targetId) {
  const targetElement = document.getElementById(targetId);

  if (targetElement) {
    const currentHeaderHeight = document.querySelector("header").offsetHeight;
    const targetOffset = targetElement.offsetTop - currentHeaderHeight;

    window.scrollTo({
      top: targetOffset,
      behavior: "smooth",
    });
  }
}

document.querySelectorAll(".nav__item a").forEach((item) => {
  item.addEventListener("click", function (e) {
    e.preventDefault();
    scrollToTarget(this.getAttribute("href").substring(1));
  });
});

document.querySelectorAll(".footer__nav__item a").forEach((item) => {
  item.addEventListener("click", function (e) {
    e.preventDefault();
    scrollToTarget(this.getAttribute("href").substring(1));
  });
});

document.querySelectorAll(".footer__link a").forEach((link) => {
  link.addEventListener("click", function (e) {
    e.preventDefault();
    scrollToTarget(this.getAttribute("href").substring(1));
  });
});

document.querySelectorAll(".hamburger-menu__item a").forEach((item) => {
  item.addEventListener("click", function (e) {
    e.preventDefault();
    scrollToTarget(this.getAttribute("href").substring(1));
  });
});

if (window.innerWidth <= 1024) {
  const infoGraphicDiagramItem8 = document.querySelector(".info-graphic__diagram-item8");
  const targetElement = document.getElementById("info-graphic-bottom");
  infoGraphicDiagramItem8.addEventListener("click", function (e) {
    e.preventDefault();
    if (infoGraphicDiagramItem8) {
      const windowHeight = window.innerHeight;
      const offset = targetElement.offsetTop - windowHeight / 2 + 85;
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  });
}
